import React from 'react'
import './Contact.scss'
import callus from './callus.jpg'
const Contact = () => {
  return (
    <div className='ct'>
        <div className='global'></div>
        <div className='p1'>
            <h1>Contact Us</h1>
            <p>We eagerly anticipate hearing your thoughts.</p>
        </div>
        <div className='p2'>
            <h2>Devsecura</h2>
            <p>Devsecura leads in cybersecurity, fortifying organizations against digital threats. We empower businesses with strong security frameworks, ensuring data integrity, operational continuity, and regulatory compliance. Focused on innovation, DevSecura safeguards digital assets in today's complex cyber landscape.</p>
        </div>
        <div className='p4i'>
            <div className='p4con'>
                <h2>Expert Consultation and Compliance Assistance</h2>
                <p>Schedule a consultation with our cybersecurity experts to explore strategic solutions, enhancing your organization's security posture and navigating regulatory challenges confidently with our expertise in compliance frameworks and data protection standards.</p>
            </div>
            <div className='p4pic'><img src={callus}/></div>
        </div>
        <div className='p4p'>
            <div className='p4'>
                <div className='ppp4'>
                    <h2>Partner and Vendor Inquiries</h2>
                    <p>Collaborate with DevSecura to deliver comprehensive cybersecurity solutions. Connect
                    with our partnership team for inquiries and opportunities.</p>
                </div>
            </div>
        </div>
        <div className='p4p4'>
            <div className='pp4'>
                <div className='p44'>
                    <div className='cont'>
                        <h2>Customer Support</h2>
                        <p>For technical support and general inquiries, our dedicated customer support team is
                        committed to providing prompt assistance.</p>
                        <ul>
                            <li>Phone: +91 7038635565</li>
                            <li>Email:  support@devsecura.com</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact