import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Navbar, Carousel, Content, Training,Services,ServiceCarousel,Load, Blog,Industries,Comingsoon,Contactus,Contact, Footer, Page3, Services1, About, Careers,Sitemap,Terms} from './containers';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <React.StrictMode>
      <Router>  
        <Navbar />
        <Routes> 
          <Route path="/" element={
              isLoading ? <Load /> : <><Carousel /><Content /><Page3 /><Contactus /></>
            } />
          <Route path= "/services" element={<><ServiceCarousel /><Services /></>} />
          <Route path="/industries" element={<><Industries /><Contactus /></>} />
          <Route path="/about" element={<><About /></>} />
          <Route path="/careers" element={<><Careers /></>} />
          <Route path="/Blog" element={<><Blog /></>} />
          <Route path="/Contact" element={<><Contact /></>} />
          <Route path="/Training" element={<><Training /></>} />
          <Route path="/Sitemap" element={<><Sitemap /></>} />
          <Route path="/Comingsoon" element={<><Comingsoon /></>} />
          <Route path="/terms-of-use" element={<><Terms /></>} />
        </Routes>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App