import React from 'react';
import './Contactus.scss';

const Contactus = () => {
  return (
    <div className="contact-us">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-item s">
              <h2 className="headline ">Careers</h2>
              <div className="description">Gain a heritage.&nbsp; Leave a legacy.</div>
              <a href="/careers" target="_blank" className="btni">
                Join Us
              </a>
            </div>
          </div>
          <div className="theline"></div>
          <div className="col-md-6">
            <div className="contact-item">
              <h2 className="headline">Contact Us</h2>
              <div className="description">What can we help you achieve.</div>
              <a href="/contact" className="btni">
                Speak With Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
