import React from 'react'
import './Terms.scss'
const Terms = () => {
  return (
    <div className='terms'>
        <div className='termsi'>
            <h1>Terms and Conditions</h1>
            <ol>
                <li>Acceptance of Terms</li>
                <ul><li>By accessing and using this website, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please refrain from using the website.</li></ul>
                <li>Website Content</li>
                <ul><li>The content on this website, including but not limited to text, graphics, images, and logos, is for informational purposes only. We make no representations or warranties about the accuracy, completeness, or reliability of the content.</li></ul>
                <li>Blog Content</li>
                <ul><li>The blog section of this website may contain opinions, advice, or recommendations. Such content is provided for general information only and should not be considered professional advice.</li></ul>
                <li>No User Data Collections</li>
                <ul><li>We do not collect any personal information from you through your use of this website. We do not require you to create an account or provide any personal details.</li></ul>
                <li>Intellectual Property</li>
                <ul><li>All intellectual property rights in the content of this website, including but not limited to trademarks, copyrights, and patents, are owned by [Your Company Name] or its licensors. You may not use, reproduce, modify, distribute, or display any content from this website without our prior written consent.</li></ul>
                <li>Limitation of Liability.</li>
                <ul><li>In no event shall [Your Company Name] be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of this website or its content.</li></ul>
                <li>Governing Law</li>
                <ul><li>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any dispute arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</li></ul>
                <li>Changes to Terms</li>
                <ul><li>We may update these Terms and Conditions from time to time. Any changes will be effective upon posting on this website. Your continued use of the website after such changes constitutes your acceptance of the modified terms.</li></ul>
                <li>Contact Information</li>
                <ul>
                    <li>Devsecura</li>
                    <li>Goa</li>
                    <li>support@devsecura.com</li>
                </ul>
            </ol>
        </div>
    </div>
  )
}

export default Terms