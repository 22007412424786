import {React,useEffect} from 'react'
import './Toptop.scss';
import tt from './arrow1.png';

const Toptop = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <div className="totop slide-in-br">
        <div className='arrowup' onClick={scrollToTop}><img src={tt}/></div>
    </div>
  )
}

export default Toptop