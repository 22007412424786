import React, { useState, useEffect } from 'react';
import "./About.scss";
import logo from "../Navbar/logo.png";
import logonav from "../Navbar/logo_nav.png";
import videoSource from "./background.mp4";
import founder1 from "./founder1.jpg";
import founder2 from "./founder2.jpg";
import founder3 from "./founder3.jpg";
import founder4 from "./founder4.png";
import st2 from "./startup2.jpg";
import qo from './Quotes1.png';
import qo1 from './Quotes2.png';
const About = () => {
    const [scrollProgress, setScrollProgress] = useState(0);
  
    useEffect(() => {
      const updateScrollProgress = () => {
        const scrollTop = document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const progress = (scrollTop / scrollHeight) * 100;
        setScrollProgress(progress);
      };
  
      window.addEventListener('scroll', updateScrollProgress);
      return () => window.removeEventListener('scroll', updateScrollProgress);
    }, []);
  
    const bright = ( Math.min(scrollProgress, 100));
    return (
<div className='About1'><div className='background1' ></div>
<div className='background' style={{ opacity: `${100 - bright}%` }}></div>

           <div className='aboutout'>
            <div className='DDS'>
                <div className='Paga1'>
                    {/* <div className='title'>
                        <img src={logonav}/>
                    </div> */}
                    <div className='Backquotes'>
                        <img className='qqq1' src={qo1} />
                        <div className='loe'>
                            <h1>Here at Devsecura, We redefine cybersecurity with innovative solutions that secure and accelerate digital transformation. Our mission is to protect and scale enterprises, pioneering excellence in today's evolving digital landscape.
                            </h1>
                        </div>
                        <img className='qqq2'src={qo} />
                    </div>
                </div> 
                <div className='Paga2'>
                    <div className='pg2'>
                    <div className='title'>
                        <h1>About Us</h1>
                    </div>
                    <div className='loe'>
                    <p>DevSecura Pvt. Ltd, our strength lies in deeply understanding our clients' business processes, culture, vision, and goals across various industry segments. We offer reliable, client-oriented solutions tailored to meet specific needs.</p>
                    <p>Since our inception in 2022, we have been committed to excellence, earning ISO 27001 certification for our information security management systems. We provide development and cybersecurity consulting services to clients globally, acting as trusted technology partners.</p>
                    <p>Our mission is to conceptualize, realize, and lead technology driven business transformation initiatives to completion. At DevSecura, we are dedicated to driving success and innovation for our clients worldwide.</p>
                    </div>
                </div>
                </div> 
                <div className='Paga3i'>
                    <div className='Paga3'>
                        <div className='title'>
                            <h1>Our Story</h1>
                        </div>
                        <div className='loe'>
                        <p>DevSecura Pvt. Ltd. began its journey in 2022 with a vision to provide unparalleled development and cybersecurity consulting services. As trusted technology partners, we help clients worldwide achieve their business goals through innovative and reliable solutions. Our commitment to customer success and securing client businesses drives everything we do. At DevSecura, we transform visions into reality with excellence and dedication.</p>
                        </div>
                    </div> 
                </div>
                <div className='Paga4'>
                    <div className='title'>
                        <h1>Meet our Founders</h1>
                    </div>
                    <div className='loe'>
                        <div className='ww'>
                            <img src={founder1}/>
                            <p className='ir'>Cheif Executive Officer</p>
                            <p className='iri'>Mr. Pranav Amonkar</p>
                        </div>
                        <div className='ww'>
                            <img src={founder2}/>
                            <p className='ir'>Director</p>
                            <p className='iri'>Mr. Prajwal Parab</p>
                        </div>
                    </div>
                </div>
                <div className='Paga5'>
                    <div className='title'>
                        <h1>Meet our Partners</h1>
                    </div>
                    <div className='loe'>
                        <div className='ww'>
                            <img src={founder3}/>
                            <p>Mr. Miguel Ribeiro</p>
                        </div>
                        <div className='ww'>
                            <img src={founder4}/>
                            <p>Mr. Antonio Viegas</p>
                        </div>
                    </div>
                </div>
                <div className="page6">
                    <div className="page6i">
                        <h2>Our Mission and Approach</h2>
                        <p>At DevSecura Pvt. Ltd., our mission is to protect digital assets and infrastructure from cyber threats and attacks. We provide expert knowledge and resources to help clients manage and mitigate cyber risks, and ensure compliance with industry standards and regulatory requirements related to cybersecurity.</p>
                        <p>Our approach is comprehensive and client-centric:</p>
                        <ol>
                            <li><b>Protection:</b> We implement robust security measures to defend against evolving cyber threats and attacks.</li>
                            <li><b>Expertise and Resources:</b> Our team of seasoned professionals offers unparalleled expertise and resources, enabling clients to effectively manage and mitigate cyber risks.</li>
                            <li><b>Compliance:</b> We assist clients in adhering to industry standards and regulatory requirements, ensuring their cybersecurity practices are up to date and compliant.</li>
                            <li><b>Client-Centric Solutions:</b> We tailor our services to meet the unique needs of each client, providing customized solutions that align with their specific business goals and security challenges.</li>
                            <li><b>Continuous Improvement:</b> We stay ahead of the curve by continuously evolving our strategies and technologies to combat the ever-changing landscape of cyber threats</li>
                        </ol>
                    </div>
                </div>
                <div className="page7">
                    <div className="page7i"> 
                        <h2>Why Chose Us?</h2>
                        <p>DevSecura, we bring unparalleled expertise and state-of-the-art technology to safeguard your digital assets. Our client-centric solutions are tailored to meet your unique business needs, ensuring comprehensive protection and compliance.</p>
                        <p>With a dedicated team of professionals, we provide continuous support and innovation, keeping your business secure and resilient. Trust DevSecura for excellence and commitment in cybersecurity, Our main goal is to equip clients with the necessary expertise, resources, and technology to protect their digital assets and infrastructure, ensuring their business remains secure and resilient. At DevSecura, we are committed to turning visionary ideas into reality with a focus on excellence and dedication.</p>
                    </div>
                </div>
            </div>
       </div>
    </div>
  )
}

export default About