import React from 'react';
import './Comingsoon.scss';
import { useNavigate } from 'react-router-dom';
import cs from './sapiens.png';
const Comingsoon = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/Contact');
    };
    return (
        <div className='outerrew'>
            <div className='innerrew'>
                <img className='rew' src={cs}/>
                <h1>Coming Soon.</h1>
                <p className='uuu'>This Page is being Developed.</p>
                <p className='uvu'>If you have any query feel free to </p>
                <button className='uwu' onClick={handleClick}>Contact Us</button>
            </div>
        </div>
    )
}

export default Comingsoon