import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Services.scss';
import icon from './icon.png'
import icon1 from './icon1.png'
import icon2 from './icon2.png'
import icon3 from './icon3.png'
import icon4 from './icon4.png'
import icon5 from './icon5.png'
import icon6 from './icon6.png'
import icon7 from './icon7.png'
import icon8 from './icon8.png'
import icon9 from './icon9.png'
import icon10 from './icon10.png'
import icon11 from './icon11.png'
import icon12 from './icon12.png'
const Services = () => {
  const services = [
    { Icon: icon, Title: 'Vulnerability Assessment and Penetration Testing (VAPT)', Description: ' Mitigate security risks through rigorous testing and analysis, ensuring your systems are resilient against cyber threats.' },
    { Icon: icon4, Title: 'Red Team Assesment', Description: 'Simulate sophisticated attacks to assess and fortify your defenses, identifying vulnerabilities before they can be exploited. Black Box Penetration Testing: At DevSecura, we assess external threat exposure through simulated attacks, fortifying security measures to safeguard your critical assets.' },
    { Icon: icon5, Title: 'API Penetration Testing', Description: 'Protect your application programming interfaces (APIs) from potential threats and vulnerabilities, ensuring the security and integrity of data exchanges Android & iOS Penetration Testing Secure the security of your mobile applications across Android and iOS platforms, ensuring the protection of sensitive data and user privacy.' },
    { Icon: icon3, Title: 'Network Infrastructure Penetration Testing', Description: 'Identify and remediate vulnerabilities in your network infrastructure, defending against unauthorized access and cyber threats.' },
    { Icon: icon2, Title: 'Web Application Penetration Testing ', Description: 'fortify your web applications against cyber threats, ensuring resilience and safeguarding critical business functions.' },
    { Icon: icon1, Title: 'OSINT (Open Source Intelligence)', Description: 'Utilize publicly available information to strengthen cybersecurity measures and proactively detect potential threats.' },
    { Icon: icon6, Title: 'Quality Assurance (QA) ', Description: 'DevSecura ensures that your software and systems meet rigorous security standards and perform reliably under varying conditions, minimizing vulnerabilities and risks.' },
    { Icon: icon10, Title: 'Cloud Penetration Testing ', Description: 'We dive deep into your cloud infrastructure to uncover hidden vulnerabilities. Our experts simulate real-world attacks to ensure your cloud environment is fortified against breaches, giving you peace of mind and robust security.' },
    { Icon: icon11, Title: 'Thick Client Penetration Testing ', Description: 'Our team scrutinizes your thick client applications, identifying and addressing security flaws that could be exploited. We ensure your desktop applications are rock-solid, protecting sensitive data and maintaining user trust.' },
    { Icon: icon7, Title: 'Thin Client Penetration Testing ', Description: 'We thoroughly test your thin client applications, uncovering potential weak points. Our meticulous approach ensures your applications remain secure, efficient, and reliable in delivering services to your users.' },
    { Icon: icon9, Title: 'Android Penetration Testing ', Description: 'We put your Android apps to the test, identifying vulnerabilities that could compromise user data. Our thorough testing process ensures your apps are secure, enhancing user confidence and safeguarding your reputation.' },
    { Icon: icon8, Title: 'iOS Penetration Testing ', Description: 'Our specialists examine your iOS apps from every angle, detecting and mitigating risks. We ensure your apps adhere to the highest security standards, providing a safe and seamless experience for your users.' },
    { Icon: icon12, Title: 'Corporate Trainings ', Description: 'Corporate Trainings for Cyber Security DevSecura equips your workforce with customized cybersecurity training programs, enhancing awareness and readiness to effectively mitigate cyber risks.' },
  ];

  const [scrollProgress, setScrollProgress] = useState(0);
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  useEffect(() => {
    setShowDiv(scrollProgress >= 40);
  }, [scrollProgress]);
  return (<>
    <div className='ServicesContainer'>
      <div className='inner'>
        <div className='Title '><h1>What We Do</h1></div>
        <div className='Services'>
          {services.map((service, serviceIndex) => ( 
            <div key={serviceIndex} className='eachService flip-in-ver-right '>
              <img src={service.Icon} alt={service.Title}/>
              <h2>{service.Title}</h2>
              <div className="ss"><p>{service.Description}</p></div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="page3">
          <div className='inp3'>
          <h1>Defending the Digital Horizon, Protecting Your Enterprise</h1>
          <p>DevSecura has been successfully delivering cybersecurity services to global clients for over years, Our commitment to protecting digital assets and providing reliable security solutions has earned us a reputation as a trusted industry partner.</p>
          </div>
          <div className='imp3'>
            <div className='imp3e'>
              <img src={icon}/>
              <p>Global reach with clients in 30+ countries.</p>
            </div>
            <div className='imp3e'>
              <img src={icon}/>
              <p>Our cybersecurity services encompass advisory, implementation, managed security, and compliance.</p>
            </div>
            <div className='imp3e'>
              <img src={icon}/>
              <p>Commitment to safeguarding your digital assets with cutting-edge cybersecurity expertise.</p>
            </div>
            <div className='imp3e'>
              <img src={icon}/>
              <p>Comprehensive advisory and consulting services tailored to meet diverse business needs.</p>
            </div>
          </div>
    </div></>
  );
};

export default Services;
