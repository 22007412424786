import React, { useState, useEffect } from 'react';
import './Careers.scss';
import people from './people.jpg';
import people1 from './people2.jpg';
const Careers = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  const leftProgress = 2 * Math.min(50 + scrollProgress, 100);
  const leftProgress1 = 1.5 * Math.min(30 + scrollProgress, 100);
  const leftProgress2 = Math.min(20 + scrollProgress, 100);
  const leftProgress01 = 2.5 * Math.min(10 + scrollProgress, 100);
  const leftProgress11 = 3 * Math.min(5 + scrollProgress, 100);
  const leftProgress21 = Math.min(15 + scrollProgress, 100);

  return (
    <div className='qwerty'>
      <div className='asdf'>
        <div className='zxcv'>
          <h1 className='poiu animate-in'>Career Development at DevSecura</h1>
          <div className="er"><h2>Discover technology-driven, human-centered experiences designed to create impactful and memorable moments that resonate with your audience.</h2>
          </div>
        </div>
        <div className='tyui'>
          <div className="mnbv d" style={{ width: `${leftProgress01}%` }} />
          <div className="mnbv e" style={{ width: `${leftProgress11}%` }} />
          <div className="mnbv f" style={{ width: `${leftProgress21}%` }} />
          <div className="mnbv a" style={{ width: `${leftProgress}%` }} />
          <div className="mnbv b" style={{ width: `${leftProgress1}%` }} />
          <div className="mnbv c" style={{ width: `${leftProgress2}%` }} />
        </div>
      </div>
      <div className="page2">
          <div className="page2i">
          <h2>Welcome to DevSecura's Career Development Page! </h2>
          <p>We are committed to nurturing talent and fostering growth within our organization. Explore the opportunities and resources we offer to help you build a rewarding career in cybersecurity</p>
          </div>
      </div>
      <div className="spage3">
        <div className="foreach">
          <div className='sw'><img src={people}/></div>
          <div className='ws'>
            <h2>Discover why DevSecura stands out:</h2>
            <p>We're committed to innovation and excellence in cybersecurity, foster a collaborative work environment with global impact, and offer career paths that align with your ambitions and interests.</p>
          </div>
        </div>
        <div className="ssss">
        <div className='sw'><img src={people1}/></div>
        <div className='ws'>
            <h2>Employee Development and Benefits at DevSecura</h2>
            <p>Explore our comprehensive training and development programs, discover opportunities for certifications and skill enhancement, andlearn about our mentorship and coaching initiatives. Benefits and Perks: Understand the benefits of working at DevSecura, including competitive compensation and healthcare packages, explore our flexible work arrangements and employee wellness programs, and learn about our commitment to diversity, equity, and inclusion.</p>
        </div>
        </div>
      </div>
      <div className="page5">
        <div className="inpage5">
          <h2>Join Our Team</h2>
          <h3>Ready to take the next step in your cybersecurity career? Apply now and become part of our dynamic team. Connect with us on LinkedIn and stay updated on career opportunities and company news.</h3>
          {/* <div className="connect">
            <div className="lk l">LinkedIn</div>
            <div className="lk e">Email</div>
            <div className="lk w">WhatsApp</div>
            <div className="lk p">Phone</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Careers;
