import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../Navbar/logo_nav.png';
import './Footer.scss';
import {FaFacebook,FaTwitter,FaLinkedin,FaYoutube,FaInstagram} from 'react-icons/fa';
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
const Footer = () => (
  <div className='of'>
  <div className="footer">
  <div className="footer-menu">
  <div className="footer-logo">
    <img src={logo} alt="Devsecura Logo" />
    <p>Powered By AxeArc</p>
  </div>
    <div className="linkss">
    <ul>
      <li><Link to="/Comingsoon" onClick={scrollToTop}>NEWS</Link></li>
      <li><Link to="/Blog" onClick={scrollToTop}>BLOGS DEVSECURA</Link></li>
      <li><Link to="/Comingsoon" onClick={scrollToTop}>LATEST UPDATES</Link></li>
      <li><Link to="/sitemap" onClick={scrollToTop}>SITE MAP</Link></li>
      <li><Link to="/careers" onClick={scrollToTop}>CAREERS</Link></li>
      <li><Link to="/Industries" onClick={scrollToTop}>INNOVATION</Link></li>
    </ul>
    </div>
    <div className="li">
    <ul>
      <li><Link to="/Industries" onClick={scrollToTop}>INDUSTRIES</Link></li>
      <li><Link to="/services" onClick={scrollToTop}>OUR SERVICES</Link></li>
      <li><a href="/training" onClick={scrollToTop}>TRAINING</a></li>
      <li><a href="/contact" onClick={scrollToTop} >CONTACT US</a></li>
      <li><a href="/about" onClick={scrollToTop}>ABOUT US</a></li>
    </ul>
    </div>
    <div className="social-media-links">
    <div>
    <a className='f' href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
    </div>
    <div className="language-dropdown">
    <select>
      <option value="en">EN</option>
    </select>
  </div>
  </div>
  </div>
  
    <div className="footer-copyright">
    <p>© 2024 DEVSECURA PVT LTD</p>
    <ul className="footer-bottom-links">
      <li><Link to="/terms-of-use" onClick={scrollToTop}>TERMS OF USE</Link></li>
      <li>PRIVACY</li>
      <li>COOKIE</li>
    </ul>
  </div>
  </div>
  </div>
);

export default Footer;
