import React from 'react';
import './Sitemap.scss';
import logo from '../Navbar/logo.png';
const Sitemap = () => {
  return (
    <div className='sitemap'>
      <div className='back'><img className='logooo' src={logo}></img></div>
      <div className='sitemapi'>
        <h1>Home Page</h1>
        <ul className='rre'>
         <div className='ttt'>
          <div className='linnn'></div>
            <div>
              <li><div className='child'><div className='lin'></div><h1>Our Services</h1></div></li>
              <li><div className='child'><div className='lin'></div><h1>Industries</h1></div></li>
              <li><div className='child'><div className='lin'></div><h1>Training</h1></div></li>
              <li><div className='child'><div className='lin'></div><h1>Careers</h1></div></li>
              <li><div className='child'><div className='lin'></div><h1>Blogs</h1></div></li>
              <li><div className='child'><div className='lin'></div><h1>Contact</h1></div></li>
              <li><div className='child'><div className='lin'></div><h1>About</h1></div></li>
            </div>
         </div>
        </ul>
      </div>
    </div>
  )
}

export default Sitemap