import React, { useState, useEffect, useCallback } from 'react';   
import './Carousel.scss';
import image1 from './PIC1.jpg';
import image2 from './PIC2.jpg';
import image3 from './PIC1v.jpg';

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slides = [
    { url: image1, title: 'DevSecura', tagline: 'Where hacker\'s meet their match.', text: 'DevSecura offers a full spectrum of cybersecurity services to meet the unique needs of your organization.' },
    { url: image2, title: 'Managing risk and keeping your business secure is our top priority.', tagline:"",text: 'DevSecura delivers all-encompassing cybersecurity solutions to manage risk and protect your business, ensuring your operations remain secure and resilient.' },
    { url: image3, title: 'Protecting systems with expertise, DevSecura ensures resilient cybersecurity solutions for secure operations. ',tagline:"" ,text: 'Our commitment to continuous improvement drives us to innovate and refine our services, ensuring you receive the most effective cybersecurity solutions available, Our consultants leverage industry best practices and regulatory compliance standards to mitigate risks and safeguard your critical assets' , misc:'true'}
  ];

  const styles = {
    background: `
      linear-gradient(to right, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      linear-gradient(to top, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${slides[activeIndex].url}) no-repeat center center / cover
    `,
  };

  const goToNext = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  }, [slides.length]);

  const goToPrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 10000);
    return () => clearInterval(interval);
  }, [goToNext]);

  return (
    <div className='Carousel'>
      <div className='slide'>
        <div className='leftControl' onClick={goToPrev}></div>
        <div className='rightControl' onClick={goToNext}></div>
        <div className='Control'>
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              className={`numps ${activeIndex === slideIndex ? 'active' : ''}`}
              onClick={() => setActiveIndex(slideIndex)}
            ></div>
          ))}
        </div>
      </div>
      <div className='slide_out' style={styles}>
        <div className='contentc' >
            <h1 style={slides[activeIndex].misc === 'true' ? { fontSize: '24px' } : {}}>{slides[activeIndex].title}</h1>
            <h2 className="tagline">{slides[activeIndex].tagline}</h2>
            <p>{slides[activeIndex].text}</p>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
