import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import p2 from './p2.jpg';
import './Training.scss'

const Training = () => {
  const divRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        });
      },
      {
        threshold: 0.5, 
      }
    );
    if (divRef.current) {
      observer.observe(divRef.current);
    }
    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsInView(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='tfp'>
        <div className='pic1'></div>
        <div className='tp1'>
            <h1>Cybersecurity Training Programs</h1>
            <p>Welcome to DevSecura's Cybersecurity Training Programs page. We offer comprehensive courses and workshops designed to equip professionals with the skills needed to defend against evolving cyber threats. Whether you're new to cybersecurity or seeking advanced skills, our programs cater to various levels of expertise.</p>
        </div>
        
        <div className='tp2' >
            <div className='uu slide-in-fwd-center'>
                <h1>Corporate Cyber Security Training for Employees.</h1>
                <p>In today's evolving digital landscape, cyber threats are persistent and diverse. Equipping your employees with essential cybersecurity knowledge and skills is vital. Our training programs empower your workforce to effectively defend against these threats.</p>
                <p className='xi'>Here's what your team will gain : </p>
                <ul className='xii'>
                    <li>- Proficiency in recognizing and thwarting phishing attempts.</li>
                    <li>- Implementation of strong password hygiene and data security practices.</li>
                    <li>- Ability to identify and report suspicious activities.</li>
                    <li>- Understanding of their role in maintaining a secure corporate environment.</li>
                </ul>
            </div>
            <img src={p2} className='flip-2-hor-top-1' />
        </div>
        <div className='encave'>
        <div className='pic2'></div>
        <div className='tp3' ref={divRef} >
            <div style={{display: isInView ? '' : 'none',}} className='slide-in-fwd-center'>
                <h2><b>Investing in cybersecurity training is essential for fortifying your business against evolving threats. By upskilling your employees, you can strategically : </b></h2>
                <h2 className='h21'>- Mitigate the risk of costly data breaches and minimize operational downtime.</h2>
                <h2 className='h22'>- Build a solid foundation of trust with your clients through enhanced brand reputation.</h2>
                <h2 className='h23'>- Foster a culture of heightened security awareness throughout your organization.</h2>
                <h2 className='h24'>- Equip your workforce with the expertise to recognize and thwart cyber threats.</h2>
                <h2 className='h25'>- Ensure compliance with industry standards and regulatory requirements.</h2>
                <h2 className='h26'>- Proactively defend sensitive information and intellectual property.</h2>
                <h2 className='h27'>- Safeguard against financial losses and potential legal liabilities.</h2>
                <h2 className='h28'>- Strengthen resilience against sophisticated cyberattacks and social engineering tactics.</h2>
                <h2 className='h29'>- Empower employees to adopt robust password management and data protection practices.</h2>
                <h2 className='h210'>- Demonstrate your commitment to proactive cybersecurity measures, bolstering business continuity and long-term success.</h2>
            </div>
        </div>
        </div>
        <div className='tp5i'>
            <div className='tp5'>
            <div>
                <h1><b>Why Choose DevSecura?</b></h1>
                <ul>
                    <li><b>- Customized Training: </b>Tailored programs to address your organization's specific cybersecurity challenges and industry requirements.</li>
                    <li><b>- Expert Instructors:</b>Learn from cybersecurity professionals with extensive experience in both training and industry practice.</li>
                    <li><b>- Practical Learning:</b> Hands-on labs and simulations that simulate real-world scenarios to reinforce learning.</li>
                </ul>
            </div>
            </div>
        </div>
        <div className='tp5i'>
        <div className='tp6'>
        <div>
            <h1><b>Benefits for Your Organization</b></h1>
            <ul>
                <li><b>- Enhanced Security Posture: </b>Tailored programs to address your organization's specific cybersecurity challenges and industry requirements.</li>
                <li><b>- Compliance Readiness:</b>: Ensure your organization meets regulatory requirements with training that aligns with industry standards.</li>
                <li><b>- Employee Engagement:</b> Invest in professional development that enhances job satisfaction and loyalty.</li>
            </ul>
            </div>
        </div>
        </div>
        <div className='las'>
            <div className='picl'></div>
            <div className='tp7'>
                <h2>Transform cybersecurity awareness into a strategic advantage for your organization. Contact DevSecura today to discuss your training needs and schedule a consultation with our cybersecurity experts. Together, we'll build a customized training plan that aligns with your business objectives and cybersecurity goals.</h2>
            </div>
        </div>
    </div>
  )
}

export default Training