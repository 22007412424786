import React, { useState, useEffect, useCallback } from 'react';   
import './Page3.scss';
import image1 from './va.jpg';
import image2 from './qa.jpg';
import image3 from './rt.jpg';
import prev from './arrowp.png';
import next from './arrow.png';
const Page3 = () => {
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);
  useEffect(() => {
    setShowDiv(scrollProgress >= 50);
  }, [scrollProgress]); 
  const slides = [
    { url: image1, color:'cyan', title: 'Vulnerability Assessment and Penetration Testing ( VAPT )', text: 'Protect your business and software infrastructure with expert VAPT services. Identify and eliminate vulnerabilities to enhance cybersecurity. Our skilled team provides comprehensive assessments and actionable recommendations to safeguard your data and comply with regulations. Build trust and resilience with proactive security measures.',hover:true },
    { url: image2, color:'yellow',title: 'Quality Assurance', text: 'Optimize Your Business and Software Infrastructure with Expert Quality Assurance Services Ensure Seamless Functionality and Reliability Across Systems. Proactively Identify and Resolve Potential Issues Before They Impact Operations. Trusted Solutions to Enhance Performance and User Experience. Streamline Processes and Enhance Productivity with Comprehensive QA Strategies. Elevate Your Cybersecurity Standards with Rigorous Quality Assurance Protocols. QA includes a variety of activites that the software meets the requirements of the customer or end-user, and that it functions as unit testing, Performance Testing , UAT/E2E Testing, Stress Testing.',hover:true  },
    { url: image3, color:'red',title: 'Red teaming', text: 'Optimize Your Business and Software Infrastructure with Expert Quality Assurance Services Ensure Seamless Functionality and Reliability Across Systems. Proactively Identify and Resolve Potential Issues Before They Impact Operations. Trusted Solutions to Enhance Performance and User Experience. Streamline Processes and Enhance Productivity with Comprehensive QA Strategies. Elevate Your Cybersecurity Standards with Rigorous Quality Assurance Protocols. QA includes a variety of activites that the software meets the requirements of the customer or end-user, and that it functions as unit testing, Performance Testing , UAT/E2E Testing, Stress Testing.' ,hover:true }
  ];

  const styles = {
    background: `
      linear-gradient(to right, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      linear-gradient(to top, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${slides[activeIndex].url}) no-repeat center center / cover
    `,
    boxShadow: `0px 0px 10px 0px ${slides[activeIndex].color}`,
    border: `1px solid ${slides[activeIndex].color}`,
  };

  let styles1 = {
    background: `
      linear-gradient(to right, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      linear-gradient(to top, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${slides[(activeIndex - 1 + 3) % 3].url}) no-repeat center center / cover
    `,
  };

  let styles2 = {
    background: `
      linear-gradient(to right, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      linear-gradient(to top, black, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url(${slides[(activeIndex + 1) % 3].url}) no-repeat center center / cover
    `,
  };

  const goToNext = useCallback(() => {
    setAnimating(true);
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
      setAnimating(false);
    }, 500);
  }, [slides.length]);

  const goToPrev = () => {
    setAnimating(true);
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
      setAnimating(false);
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 15000);
    return () => clearInterval(interval);
  }, [goToNext]);

  return (
    <div className='CarouselContainer'>
      <div className='slide'>
        <div className='navigationDots'>
          <div className='pv' onClick={goToPrev}><img src={prev} /></div>
          <div className='dottt'>{slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              className={`dot ${activeIndex === slideIndex ? 'activeDot' : ''}`}
              onClick={() => {
                setAnimating(true);
                setTimeout(() => {
                  setActiveIndex(slideIndex);
                  setAnimating(false);
                }, 500);
              }}
            ></div>
          ))}</div>
          <div className='nv' onClick={goToNext}><img src={next} /></div>
        </div>
      </div>{showDiv && 
      <div
        className={`sidechicks slide-left ${animating ? 'animating' : ''}`}
        style={styles1}
        onClick={goToPrev}
      >
        <div className='contentContainer'>
          <h1>{slides[(activeIndex - 1 + 3) % 3].title}</h1>
        </div>
      </div>}
      {showDiv && 
      <div className='slideWrapper text-focus-in' style={styles}>
        <div className='contentContainer'>
          <h1>{slides[activeIndex].title}</h1>
          <p>{slides[activeIndex].text}</p>
          {
            slides[activeIndex].hover && <div className='rm'>hover to read more</div>
          }
        </div>
      </div>}
      {showDiv && 
      <div
        className={`sidechicks slide-right ${animating ? 'animating' : ''}`}
        style={styles2}
        onClick={goToNext}
      >
        <div className='contentContainer'>
          <h1>{slides[(activeIndex + 1) % 3].title}</h1>
        </div>
      </div>}
    </div>
  );
};

export default Page3;
