import React, { useState } from 'react';
import './Industries.scss';
import cyber from './image1.jpg';
import web from './image2.jpg';
import coming from './comingsoon.png';
import up from "./up.png";
import down from "./down.png";

const Industries = () => {
  const [isBlogsVisible, setBlogsVisible] = useState(false);

  const handleOppClick = () => {
    setBlogsVisible(!isBlogsVisible);
  };
  return (
    <div className='outindus'>
     <div className='page1'>
      <div className='s'>
        <h1>Devsecura's industries</h1>
        <h2>DevSecura provides top-tier cybersecurity solutions for the financial services sector, ensuring the protection of sensitive financial data and compliance with stringent industry regulations</h2>
      </div>
     </div>
     <div className='page2'>
      {/* <img className="bgc" src={cyber}/> */}
      <div className='ctn'>
        <h2>Enabling rapid and scalable growth in the high-tech sector.</h2>
        <h3>At DevSecura Cyber Security, we harness digital inovation to develop solutions and scalable products for our high-tech clients globally. Through our proficiency in product engineering, business scaling, and customer-centric experiences, we empower our clients to thrive in the digital age. we adeptly navigate the complexities of the digital landscape, integrating advanced AI capabilities to deliver impactful results</h3>
        </div>
      </div>
      <div className='page3i'>
      <div className='ctn'>
        <h2>DevSecura Cyber Security, we remain at the forefront of technological advancements by harnessing digital innovation to develop scalable solutions for our global high-tech clients.</h2>
        <h3>Who We serve</h3>
        <div className='wws'>
          <div className='ee a'><div className='zzz'>Banking</div></div>
          <div className='ee b'><div className='zzz'>Healthcare</div></div>
          <div className='ee c'><div className='zzz'>Fintech</div></div>
          <div className='ee d'><div className='zzz'>Telecom</div></div>
          <div className='ee e'><div className='zzz'>Ecommerce</div></div>
          <div className='ee f'><div className='zzz'>Airports</div></div>
          <div className='ee g'><div className='zzz'>Shipments</div></div>
          <div className='ee h'><div className='zzz'>Companies</div></div>
          <div className='ee i'><div className='zzz'>Pharamcy</div></div>
          <div className='ee j'><div className='zzz'>Stock Trading Companies</div></div>
          <div className='ee k'><div className='zzz'>Venture Capital</div></div>
          <div className='ee l'><div className='zzz'>Manufacturing</div></div>
          <div className='ee m'><div className='zzz'>Insurance</div></div>
          <div className='ee n'><div className='zzz'>Artificial Intelligence</div></div>
        </div>
        </div>
      </div>
      <div className="page4">
        <div>Innovative, scalable, customer-centric leadership</div>
        <div className='opp' onClick={handleOppClick}>
          Blog
          {isBlogsVisible ? <img src={up} alt="open" /> : <img src={down} alt="close" />}
        </div>{isBlogsVisible && (
        <div classNmae="vvvvv">
           <img src={coming}/>
        </div>)}
      </div>
    </div>
  );
};

export default Industries; 