import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import logo from './logo.png';
import Menu_icon from './menu.png';
import close_icon from './close.png';
import { Toptop } from '../index.js';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);

  const main_links = [
    { Title: 'OUR SERVICES', Links: '/services' },
    { Title: 'INDUSTRIES', Links: '/industries' },
    { Title: 'TRAINING', Links: '/Training' },
    { Title: 'CAREERS', Links: '/careers' },
    { Title: 'BLOGS', Links: '/Comingsoon' }, //'/Blog'
    { Title: 'CONTACT', Links: '/Contact' },
    { Title: 'ABOUT', Links: '/about' }
  ];

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);

  useEffect(() => {
    setShowDiv(scrollProgress <= 2);
  }, [scrollProgress]);

  useEffect(() => {
    if (toggleMenu) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [toggleMenu]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1400) {
        setShowHamburger(true);
      } else {
        setShowHamburger(false);
        setToggleMenu(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className={showDiv ? 'navbar xy' : 'navbar'}>
        <div className='container'>
          <div className='logo'>
            <a href="/" className='logo'>
              <img src={logo} alt='devsecura' />
            </a>
          </div>
          {!showHamburger && (
            <div className='links'>
              {main_links.map((main_linksData, main_linksIndex) => (
                <span key={main_linksIndex} className='grow'>
                  <Link onClick={scrollToTop} to={main_linksData.Links}>{main_linksData.Title}</Link>
                </span>
              ))}
            </div>
          )}
          {showHamburger && (
            <div className='menu-icon' onClick={() => setToggleMenu(!toggleMenu)}>
              <img src={toggleMenu ? close_icon : Menu_icon} alt='menu icon' style={toggleMenu ? { width: '25px', height: '20px' } : {}} />
            </div>
          )}
        </div>
        {toggleMenu && showHamburger && (
          <div className='hamburger-menu'>
            {main_links.map((main_linksData, main_linksIndex) => (
              <div key={main_linksIndex} className='links'>
                <span className='grow'>
                <Link
        onClick={() => {
          scrollToTop();
          setToggleMenu(!toggleMenu);
        }}
        to={main_linksData.Links}
      >{main_linksData.Title}</Link>
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      {!showDiv && <Toptop />}
    </>
  );
};

export default Navbar;
