import React, { useState, useEffect } from 'react';
import './Content.scss'
const Content = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const updateScrollProgress = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', updateScrollProgress);
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);
  useEffect(() => {
    setShowDiv(scrollProgress >= 10);
  }, [scrollProgress]); 

  const leftProgress = 2 * Math.min(scrollProgress, 100);
  const leftProgress1 = 1.5 * (Math.min(scrollProgress, 100))
  const leftProgress2 = Math.min(scrollProgress, 100);
  return (
    <div className='outerc'> 
      <div className='content'>
      {showDiv && 
      <div className='HeadText'>
          <h1 className='space-grotesk text-focus-in'>Accelerating Your Cyber Security Transformation: Achieving Business Excellence via Seamless Systems Integration</h1>
          <div className="Boxx slide-in-bottom">
            <h2>Drive your business with Devsecura seamless systems integration, accelerating your cyber-driven transformation.</h2>
            <p>By partnering with Devsecura, you gain access to a team of cybersecurity experts dedicated to understanding your unique challenges and delivering scalable solutions. We prioritize proactive measures to mitigate cyber threats, ensuring that your organization remains secure amidst evolving risks</p>
          </div>
      </div>}
        <div className="containtriangle"><div className='triangle'>
        <div className="ThatRedLine left" style={{ width: `${leftProgress}%` }} />
        <div className="ThatRedLine left1" style={{ width: `${leftProgress1}%` }} />
        <div className="ThatRedLine left2" style={{ width: `${leftProgress2}%` }} />
        </div></div>
      </div>
    </div>
  )
}

export default Content