import React from 'react';
import './Blog.scss';

const Blog = () => {
  const blogs = [
    { title: "This is The First Blog on devsecura", meta: "This is the first blog on devsecura.com." },
    { title: "Devsecura Introducing AXEARC", meta: "AXEARC is a web-development company." },
  ];

  return (
    <div className='blog'>
      <div className='bp1'>
        <h1>Devsecura Blog's</h1>
        <p>Welcome to the DevSecura Blog! Our mission is to keep you informed and empowered with the latest insights, trends, and best practices in cybersecurity. Whether you're an individual looking to protect your personal data or a business aiming to safeguard your digital assets, our blog aims to provide valuable information to enhance your cybersecurity posture.</p>
        <div className='cate'>
            <div className='categ'>All Topics</div>
        </div>
      </div>
      <div className='bp2'>
        {blogs.map((blog, index) => (
          <div key={index} className='blog-entry'>
            <h2>{blog.title}</h2>
            <p>{blog.meta}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
