import React from 'react'
import logo from '../Navbar/logo.png';
import './Load.scss'
const Load = () => {
  return (
    <div className='load'>
        {/* <video className="video" autoPlay muted>
            <source src={loader} type="video/mp4" />
        </video> */}
        <div className='loadcenterout '>
            <div className='loadcenter swing-in-top-fwd'>
                <img src={logo} className='imgl'/>
                <p className='fgdg'>Powered By AxeArc</p>
            </div>
            <div className='progress slide-fwd-center'><div className='bar'></div></div>
        </div>
    </div>
  )
}

export default Load